.home {
  .main {
    background: #ffffff;
    background: -moz-linear-gradient(top,  #ffffff 0%, #edf4f6 100%);
    background: -webkit-linear-gradient(top,  #ffffff 0%,#edf4f6 100%);
    background: linear-gradient(to bottom,  #ffffff 0%,#edf4f6 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edf4f6',GradientType=0 );
    padding-left: 3rem;

    img {
      margin-bottom: 0;
    }
    h1 {
      float: left;
    }
    p {
      float: left;
      -webkit-margin-before:0em;
      -webkit-margin-after:0em;
    }
    .bx-controls-auto {
      display: none !important;
    }
    .heading {
      margin-bottom: 3rem;
    }
  }
  .page-header {
    h1 {
      display: none;
    }
  }
  .iconbar {
    background-color: #627A81;
    padding: 1rem 3rem;

    .icon {
      height: 4rem;
      width: 4.375rem;
      padding-right: 1rem;
    }
    a, a:link, a:visited, a:active, a:hover, a:focus,
    a:not([href]):not([tabindex]), a:not([href]):not([tabindex]):focus, a:not([href]):not([tabindex]):hover {
      color: white !important;
      padding-right: 1rem;
      font-size: 90%;
      cursor: pointer !important;
    }
  }
  .pinkbar {
    background-color: $pink;
    color: white;
    padding-top: 2rem;
    padding-bottom: 2rem;
    min-height: 280px;

    .left {
      padding-left: 5rem;
    }
    .right {
      padding-right: 5rem;
    }
    #panelblank {
      min-height: 168px;
      background-color: transparent;
    }
  }
  .membership {
    padding: 5rem;
    margin: 0 auto;

    div:center-block();

    a {
      padding:1rem;
    }

    img {
      max-height: 88px;
    }
  }
}
/* tablet styles */
@media (min-width: 768px) and (max-width: 1200px){
  .home {
    .main {
      padding-left: 1.5rem;
    }
    .iconbar {
      padding: 1rem;

      a {
        padding: 1rem;

        :active, a:visited, a:focus {
          color: #EBF4F6;
        }

        img {
          display: none;
        }
      }
    }
    .pinkbar {
      .left {
        padding-left: 0;
        padding-right: 1rem;
      }
      .right {
        padding-left: 1rem;
        padding-right: 0;
      }
      .icon {
        max-width: 150px;
      }
    }
    .membership {
      padding: 1rem;
    }
  }
}
/* Mobile */
@media (max-width: 767px) {
  .home {
    .main {
      padding-left: 1rem;
    }
    .iconbar {
      padding: 1rem;

      a {
        padding: 1rem;
      }
    }
    .pinkbar {
      .left {
        padding-left:0;
        .icon {
          display: none;
        }
      }
      .right {
        padding-right:0;
        .icon {
          display: none;
        }
      }
    }
    .membership {
      padding: 1rem;

      img {
        max-width: 85%;
      }
    }
  }
}
