body {
  font-family: 'Roboto', sans-serif;
  color: $font-color;
}
.home {
  h1 {
    font-size: 1.5rem;
    color: $brand-primary;
  }
}
blockquote {
  font-size: 1rem;
  line-height: 1.6rem;
}
