.container {
  clear: both;
}
.clear {
  clear: both;
}
.left {
  float: left;
}
.right {
  float: right;
}
.center {
  margin: 0 auto;
  display: block;
  text-align: center;
}
.main {
  background: #ffffff;
  background: -moz-linear-gradient(top,  #ffffff 0%, #edf4f6 100%);
  background: -webkit-linear-gradient(top,  #ffffff 0%,#edf4f6 100%);
  background: linear-gradient(to bottom,  #ffffff 0%,#edf4f6 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#edf4f6',GradientType=0 );
}
.no-bg {
  background: none;
}
blockquote {
  border-left: none;
}
.blue {
  color: $blue;
}
.greybar {
  background-color: #627A81;
  padding: 2rem 3rem;
  color: white;

  h2 {
    font-weight: bold;
    padding-bottom: 1rem;
  }
}
.appointments {
  padding: 5rem;
  margin: 0 auto;

  h2 {
    margin-bottom: 3rem;
  }

  div:center-block();

  a {
    padding:1rem;
  }
}
.img-rounded {
  border-radius: 6px;
}
.panel-body {
  .icon {

  }
}
/* tablet styles */
@media (min-width: 768px) and (max-width: 1196px){
  .appointments {
    padding: 1rem;
  }
}
@media (max-width: 767px) {
  .greybar {
    padding: 1rem 2rem;

    .icon {
      margin: 0 auto;
      float: none;
    }
  }
  .appointments {
    padding: 1rem;

    .icon {
      max-width: 80%;
    }
  }
}
