.banner {
  margin-top: 1em;
}
.brand {
  float: left;
}
.nav-primary {
  float: right;
}
.page-header {
  border-bottom: none;
}
