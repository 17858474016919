// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$brand-primary:         #627A81;
$font-color:            #688493;
$pink:                  #E49EC5;
$blue:                  #6DA8C2;
