.navbar-default {
  .navbar-right {
    ul {
      list-style-type: none;
      margin: 0;
      padding: 0;
    }

    li {
      display: inline;
      float: left;
    }
    li a {
        display: block;
        color: $brand-primary;
        text-align: center;
        padding: 4rem 2rem;
        text-decoration: none;
    }

    /* Change the link color to #111 (black) on hover */
    li a:hover {
        color: $pink;
    }
  }

  .navbar-toggle {
      background-color: white;
      border-color: black;

      .icon-bar {
          background-color: black;
      }
  }

	.navbar-nav {
		a,a:hover,a:link,a:visited {
      color: $pink;
		}
    .active a,a:focus,.active a:hover,a:hover {
      color: $pink;
		}
    .open > a, .open > a:hover, .open > a:focus, .dropdown-menu {
      color: $pink;
    }
  }
}
.navbar-default {
  background-color: #ffffff;
  border-color: #ffffff;
}
@media (min-width: 768px) and (max-width: 992px){
  .navbar-default {
    .navbar-right {
      li a {
        padding: 2rem 1.8rem;
        font-size: 90%;
      }
    }
  }
  .page-header {
    margin-top: 0;
  }
}
@media (max-width: 767px) {
  .navbar-default {
    .navbar-right {
      li a {
        padding: .75rem;
        font-size: 90%;
      }
      margin-top: 1rem;
      border-top: 1px solid transparent;
    }
  }
}
